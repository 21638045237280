import React, { useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "../StyleSheet/FileViewStyle.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import SignatureCanvas from "react-signature-canvas";
import { useLocation } from "react-router-dom";
import { PDFDocument } from "pdf-lib";
import {
  fetchGet,
  fetchPostSaveSign,
  fetchPostSaveSubscription,
} from "../Utils/FetchApis";
import cross from "../Assets/Cross.webp";
import ApiNames from "../Constants/ApiNames";
import ArrowR from "../Assets/right.png";
import ArrowL from "../Assets/left.png";
import clearImg from "../Assets/clear.png";
import Brand from "./Brand";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import ProccessingModal from "./ProccessingModal";
import List from "./List";
import background from "../Assets/MandeepCard.webp";
import "../StyleSheet/BackgroundImageStyle.css";
import background2 from "../Assets/Ad2.webp";
import EmailValidationModal from "./EmailValidationModal";
export default function PDF(props) {
  const search = useLocation().pathname;
  // const companySearch = useLocation().search;
  // const companyName = new URLSearchParams(companySearch).get("company")
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [getSignaturePad, setGetSignaturePad] = useState(false);
  const [responseId, setResponseId] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [clientEmail, setClientEmail] = useState("");
  const [orignalClientEmail, setOrignalClientEmail] = useState("");
  const [clientName, setClientName] = useState("");
  const [buttonhandel, setButtonHandle] = useState(false);
  const [file, setFile] = useState(null);
  const [docSigned, setDocSigned] = useState(false);
  const [brandNames, setBrandNames] = useState([]);
  const [subSuccess, setSubSuccess] = useState(false);
  const [showBtn, setShowBtn] = useState(true);
  const [ErrFailure, setErrFailure] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [processingShow, setProcessingShow] = useState(true);
  const [formLength, setFormLength] = useState([]);
  const [errText, setErrText] = useState("");
  const [savePdfArray, setSavePdfArray] = useState([]);
  const [listIndex, setListIndex] = useState(0);
  const [brandStatus, setBrandStatus] = useState([]);
  const [processingText, setProcessingText] = useState("Loading PDF Forms.");
  const [showAdds, setShowAdds] = useState(false);
  const [flagAll, setFlagAll] = useState(false);
  const [logo, setLogo] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const windowWidth = useRef(window.innerWidth);
  let sigPad = {};
  const ref = useRef();
  const qureyString = search;
  // const GRBI_response = {
  //   status: 0,
  //   response: {
  //     id: 1,
  //     documents: [
  //       {
  //         formUrl:
  //           "https://last-minute-expense.000webhostapp.com/individual183.pdf",
  //         name: "T183 Form",
  //         type: "individual_183",
  //         signatureLocations: [
  //           {
  //             page: 0,
  //             x: 100,
  //             y: 230,
  //           },
  //         ],
  //       },
  //       {
  //         formUrl:
  //           "https://last-minute-expense.000webhostapp.com/individualT1135.pdf",
  //         name: "T1135 Form",
  //         type: "individualT1135",
  //         signatureLocations: [
  //           {
  //             page: 2,
  //             x: 100,
  //             y: 640,
  //           },
  //         ],
  //       },
  //       {
  //         formUrl:
  //           "https://last-minute-expense.000webhostapp.com/individualT1032.pdf",
  //         name: "T1032 Form",
  //         type: "individualT1032",
  //         signatureLocations: [
  //           {
  //             page: 3,
  //             x: 100,
  //             y: 300,
  //           },
  //           {
  //             page: 3,
  //             x: 200,
  //             y: 280,
  //           },
  //         ],
  //       },
  //       {
  //         formUrl:
  //           "https://last-minute-expense.000webhostapp.com/individualAUTH.pdf",
  //         name: "AUTH Form",
  //         type: "individualAUTH",
  //         signatureLocations: [
  //           {
  //             page: 0,
  //             x: 170,
  //             y: 300,
  //           },
  //         ],
  //       },
  //       {
  //         formUrl:
  //           "https://last-minute-expense.000webhostapp.com/corporateRC59.pdf",
  //         name: "RC59 Form",
  //         type: "corporateRC59",
  //         signatureLocations: [
  //           {
  //             page: 0,
  //             x: 180,
  //             y: 80,
  //           },
  //         ],
  //       },
  //       {
  //         formUrl:
  //           "https://last-minute-expense.000webhostapp.com/corporate183.pdf",
  //         name: "C183 Form",
  //         type: "corporate183",
  //         signatureLocations: [
  //           {
  //             page: 1,
  //             x: 270,
  //             y: 580,
  //           },
  //         ],
  //       },
  //     ],
  //     amount: 69.0,
  //     requestDate: "2023-01-27T16:30:46",
  //     client: {
  //       id: 1,
  //       name: "test indiv",
  //       type: "INDIVIDUAL",
  //       isIndividual: true,
  //       email: "asd@d.com",
  //       mobileNumber: "1234",
  //       sinNumber: "4321",
  //       authorizedPersonName: null,
  //       corporateBusinessNumber: null,
  //       dateAdded: "2023-04-14T10:56:57",
  //     },
  //   },
  // };

  useEffect(() => {
    if (props.token) {
      GetRequestById();
    }
  }, [props.token]);

  const GetRequestById = async () => {
    try {
      let path = qureyString.slice(1, qureyString.length);
      let response = await fetchGet(
        ApiNames.getRequest + "?id=" + path,
        props.token
      );
      if (response.status === 0) {
        setFormLength(response.response.documents);
        setFile(response.response.documents[0].formUrl);
        setResponseId(response.response.id);
        setClientId(response.response.client.id);
        setClientEmail(response.response.client.email);
        setOrignalClientEmail(response.response.client.email);
        setClientName(response.response.client.name);
        setCompanyName(response.response.companyName);
        setLogo(response.response.logo);
        setTimeout(() => {
          setProcessingShow(false);
        }, 1000);
      } else if (response.status === 1) {
        setProcessingShow(false);
        setErrText(response.response);
        setErrFailure(true);
      } else {
        setProcessingShow(false);
        setErrText("Oops.. Something went wrong. Please try again.");
        setErrFailure(true);
      }
    } catch (err) {
      setProcessingShow(false);
      setErrText(err);
      setErrFailure(true);
    }
  };

  const SaveSignedFile = async (Id, Pdf) => {
    try {
      let response = await fetchPostSaveSign(
        ApiNames.saveSignedFile,
        Id,
        clientEmail,
        Pdf,
        props.token
      );
      if (response.status === 0) {
        // GetBrands();
        setDocSigned(true);
        setProcessingShow(false);
      } else if (response.status === 1) {
        setProcessingShow(false);
        setErrText(response.response);
        setErrFailure(true);
      } else {
        setProcessingShow(false);
        setErrText("Oops.. Something went wrong. Please try again.");
        setErrFailure(true);
      }
    } catch (err) {
      setProcessingShow(false);
      setErrText(err);
      setErrFailure(true);
      console.log("failed: " + err);
    }
  };

  // const GetBrands = async () => {
  //   let arr = [];
  //   var res = [];
  //   try {
  //     let response = await fetchGet(ApiNames.getBrands, props.token);
  //     if (response.status === 0) {
  //       setDocSigned(true);
  //       res = response.response;
  //       if (res.length > 0) {
  //         setProcessingShow(false);
  //         let checkTemp = [];
  //         for (let i = 0; i < res.length; i++) {
  //           checkTemp.push(false);
  //         }
  //         setBrandStatus(checkTemp);

  //         setBrandNames(response.response);
  //       } else {
  //         setProcessingShow(false);
  //         setSubSuccess(true);
  //       }
  //     } else {
  //       setProcessingShow(false);
  //       setSubSuccess(true);
  //       console.log("Something went wrong. Please try again.");
  //     }
  //   } catch (err) {
  //     setSubSuccess(true);
  //     console.log("failed: " + err);
  //   }
  // };

  const pdfListHandler = (index) => {
    setListIndex(index);
    if (savePdfArray.length > 0) {
      setFile("data:application/pdf;base64," + savePdfArray[index].pdfBase64);
    } else {
      setFile(formLength[index].formUrl);
    }
  };
  const oncheckHandler = () => {
    setButtonHandle(!buttonhandel);
  };
  const onListcheckHandler = (index) => {
    let temp = [...brandStatus];
    if (brandStatus[index]) {
      temp[index] = false;
    } else {
      temp[index] = true;
    }
    setBrandStatus(temp);
  };
  useEffect(() => {
    if (flagAll) {
      let temp = [...brandStatus];
      for (let i = 0; i < temp.length; i++) {
        temp[i] = true;
      }
      setBrandStatus(temp);
    } else {
      let temp = [...brandStatus];
      for (let i = 0; i < temp.length; i++) {
        temp[i] = false;
      }
      setBrandStatus(temp);
    }
  }, [flagAll]);

  const onListCheckAllHandler = () => {
    setFlagAll(!flagAll);
  };

  const onhandelSubscribe = async () => {
    try {
      let tempId = [];
      // for (let i = 0; i < brandStatus.length; i++) {
      //   if (brandStatus[i]) {
      //     tempId.push(brandNames[i].id);
      //   }
      // }
      // if (tempId.length > 0) {
      let response = await fetchPostSaveSubscription(
        ApiNames.saveSubscriptions,
        clientId,
        props.token
      );
      if (response.status === 0) {
        setSubSuccess(true);
      } else {
        setSubSuccess(true);
        console.log("Something went wrong. Please try again.");
      }
      // } else {
      //   alert("Select Atleast One Barand ...!");
      // }
    } catch (err) {
      setSubSuccess(true);
      console.log("failed: " + err);
    }
  };

  const Retry = () => {
    if (props.token) {
      setProcessingText("Loading PDF form.");
      setProcessingShow(true);
      setErrFailure(false);
      setShowBtn(true);
      setSubSuccess(false);
      GetRequestById();
    }
  };
  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    // setProcessingShow(false);
    setNumPages(numPages);
    setPageNumber(1);
  }

  function addChangePage(offset) {
    if (pageNumber < numPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }
  }
  function negChangePage(offset) {
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }
  }

  function previousPage() {
    negChangePage(-1);
  }

  function nextPage() {
    addChangePage(1);
  }

  const onClickHandleSign = () => {
    setGetSignaturePad(true);
  };
  const onhandleEmailValidation = () => {
    const emailRegex = new RegExp(
      "^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$",
    );
    const regex=emailRegex.test(clientEmail)
    setIsValidEmail(regex);

    if (regex) {
      setEmailModal(false)
      onClickHandleSave();
    }
  };

  const onClickHandleSave = () => {
    if (responseId !== null && savePdfArray.length > 0) {
      setProcessingText("Submiting Documents.");
      setProcessingShow(true);
      SaveSignedFile(responseId, savePdfArray);
    }
  };
  const onhandelcancelBrand = () => {
    setDocSigned(false);
    setSubSuccess(true);
  };

  const handleSignature = (signature) => {};
  const cancel = () => {
    setGetSignaturePad(false);
    // setProcessingText("Loading PDF form");
    // setProcessingShow(true);
  };
  const clear = () => {
    sigPad.clear();
  };
  const save = async () => {
    try {
      const pngUrl = sigPad.getTrimmedCanvas().toDataURL("image/png");
      if (pngUrl.length === 130) {
        alert("Please Sign First....!");
      } else {
        setGetSignaturePad(false);
        setProcessingShow(true);
        let tempArray = [];
        for (var i = 0; i < formLength.length; i++) {
          setProcessingText("Signing Your " + formLength[i].name);

          const existingPdfBytes = await fetch(formLength[i].formUrl).then(
            (res) => res.arrayBuffer()
          );
          const pdfDoc = await PDFDocument.load(existingPdfBytes);
          const pages = pdfDoc.getPages();
          for (var j = 0; j < formLength[i].signatureLocations.length; j++) {
            const firstPage = pages[formLength[i].signatureLocations[j].page];
            let pdfHeight = firstPage.getHeight();
            const pngImageBytes = await fetch(pngUrl).then((res) =>
              res.arrayBuffer()
            );
            const pngImage = await pdfDoc.embedPng(pngImageBytes);
            let pdfY = formLength[i].signatureLocations[j].y;
            pdfY = pdfY * 1;
            pdfY = pdfHeight - pdfY - 50 * 1;
            const pngDims = pngImage.scale(1);
            firstPage.drawImage(pngImage, {
              x: formLength[i].signatureLocations[j].x,
              y: pdfY,
              width: 45,
              height: 45,
            });
          }
          var pdfBase = await pdfDoc.saveAsBase64();
          setShowBtn(false);
          tempArray.push({
            type: formLength[i].type,
            pdfBase64: pdfBase,
          });
        }
        if (tempArray.length > 0) {
          setSavePdfArray(tempArray);
          setListIndex(0);
          setFile("data:application/pdf;base64," + tempArray[0].pdfBase64);
          setTimeout(() => {
            setProcessingShow(false);
          }, 1000);
        }
      }
    } catch (err) {
      setProcessingShow(false);
      setErrText(
        "Dear User, The form data is missing; please try again later or contact the system administrator for further assistance."
      );
      setErrFailure(true);
    }
  };

  const onOK = () => {
    setShowAdds(true);
    setSubSuccess(false);
  };
  const onchangeEmail=(val)=>{
    setClientEmail(val.target.value)
  }
  const handleClose=()=>{
    setClientEmail(orignalClientEmail)
    setIsValidEmail(true);
    setEmailModal(false)
  }
  return (
    <>
      <ProccessingModal
        modalShow={processingShow}
        processingText={processingText}
      />
      <ErrorModal modalShow={ErrFailure} Retry={Retry} errText={errText} />
      <SuccessModal
        modalShow={subSuccess}
        onOK={onOK}
        text=", you have successfully signed your Tax Forms. For any query, please feel free to contact Admin. Thanks."
        clientName={clientName}
        CompanyName={CompanyName}
      />
      <EmailValidationModal
        modalShow={emailModal}
        onOK={onhandleEmailValidation}
        value={clientEmail}
        onChange={onchangeEmail}
        isValidEmail={isValidEmail}
        handleClose={handleClose}
      />
      {subSuccess === false && showAdds === false ? (
        <>
          {getSignaturePad ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="docMain">
                <img src={logo} className="mainLogo" />
                <div className="clientHeading">Client’s Signature</div>
                <div className="sigCanvas">
                  <SignatureCanvas
                    ref={(ref) => {
                      sigPad = ref;
                    }}
                    onOK={(sig) => handleSignature(sig)}
                    descriptionText="Sign"
                    onClear={clear}
                    penColor="rgba(25, 73, 136, 1)"
                    dotSize={1}
                    canvasProps={{
                      width:
                        windowWidth.current < 767
                          ? windowWidth.current / 1.1
                          : windowWidth.current / 2.5,
                      height: 250,
                      className: "signWidth",
                    }}
                  />
                </div>
                <div className="btnContainerSign">
                  <div className="clearContainer" onClick={clear}>
                    <img src={clearImg} className="clearImg" />
                    <div onClick={clear} className="clearDesc">
                      Clear
                    </div>
                  </div>
                  <button className="saveSignButton" onClick={save}>
                    Save
                  </button>
                  <div className="cancelButton" onClick={cancel}>
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {docSigned ? (
                <>
                  <Brand
                    brandNames={brandNames}
                    oncheckHandler={oncheckHandler}
                    onListcheckHandler={onListcheckHandler}
                    onListCheckAllHandler={onListCheckAllHandler}
                    onhandelcancelBrand={onhandelcancelBrand}
                    onhandelSubscribe={onhandelSubscribe}
                    buttonhandel={buttonhandel}
                    brandStatus={brandStatus}
                    flagAll={flagAll}
                  />
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="docMain">
                    <img src={logo} className="mainLogo" />
                    <div className="clientNameContainer">
                      <div className="clientNameHead">Client Name</div>
                      <div className="clientNameDesc">{clientName}</div>
                    </div>
                    <List
                      formLength={formLength}
                      pdfListHandler={pdfListHandler}
                      listIndex={listIndex}
                    />
                    {file && (
                      <div className="main">
                        <Document
                          file={file}
                          onLoadSuccess={onDocumentLoadSuccess}
                          onLoadError={() => {}}
                          className="DocPDF"
                        >
                          <Page
                            scale={windowWidth.current < 992 ? 0.6 : 1}
                            pageNumber={pageNumber}
                          />
                        </Document>
                      </div>
                    )}

                    <div>
                      <div className="containerPagination">
                        <img src={ArrowL} width="20px" onClick={previousPage} />

                        <div className="pagec">
                          Page {pageNumber || (numPages ? 1 : "--")} /{" "}
                          {numPages || "--"}
                        </div>

                        <img src={ArrowR} width="20px" onClick={nextPage} />
                      </div>
                    </div>

                    {showBtn ? (
                      <>
                        <button
                          className="signButton"
                          onClick={onClickHandleSign}
                        >
                          Sign Document
                        </button>
                      </>
                    ) : (
                      <div className="buttonContainers">
                        <button
                          className="editSignButton"
                          onClick={onClickHandleSign}
                        >
                          Edit Sign Document
                        </button>
                        <button
                          className="saveButton"
                          onClick={() => setEmailModal(true)}
                        >
                          SUBMIT
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : subSuccess === false && showAdds === true ? (
        <>
          {CompanyName === "Pro Tax Block" ? (
            <div className="imgMainContainer">
              <div className="imgSubContainer">
                <img src={background} className="imgTag" />
              </div>
            </div>
          ) : (
            <div className="imgMainContainer">
              <div className="imgSubContainer">
                <img src={background} className="imgTag" />
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
}
