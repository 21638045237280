import React from "react";
import "../StyleSheet/ModalStyle.css";
import Modal from "react-bootstrap/Modal";
import tick from "../Assets/tick.png";
import Form from "react-bootstrap/Form";

export default function EmailValidationModal(props) {
  return (
    <Modal
      show={props.modalShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.handleClose}
    >
      <div className="successCardContainer">
        <div className="successCardHeading" style={{padding:"0px 9px",fontSize:"22px"}}>We'll send your documents to the email address you provide below.</div>
        <div className="" style={{width: "90%"}}>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={props.value}
                onChange={(e) => props.onChange(e)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </div>
        {!props.isValidEmail && (
          <span style={{ color: "red" }}>Invalid email address</span>
        )}
        <button className="errorCardButton" onClick={props.onOK}>
          OK
        </button>
      </div>
    </Modal>
  );
}
